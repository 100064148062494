import React, { Component } from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BuddySliderBanner, LayoutObject } from '../../components/Grid';
import VisibilitySensor from 'react-visibility-sensor';

import { Image, Sprite } from '../../components/index';
import { GridSection, GridBox } from '../Grid';
import { CarouselWrapper } from './ImageCarousel';
import { Heading2, Text } from '../../helper/Typography';
import { CarouselControls } from './CarouselControls';
import { SimpleImage } from '../../helper/StyledHelper';
import { dogLayout, patternsLayout } from '../../templates/jobs/layouts';
import { dogStates } from '../../templates/jobs/animations';

type JobSliderProps = {
  setAnimationState: (animation: string, animState: number) => void;
  teamSlides: {
    banner: { label: string; title: string };
    text: string;
    imageRoute: string;
  }[];
  darkTheme: boolean;
  sectionHeight: {
    intro: number[];
    image: number[];
    textSection: number[];
    imagesSection: number[];
    textSection2: number[];
    imagesSection2: number[];
    jobOffersFrankfurt: number[];
    jobOffersBerlin: number[];
    banner: number[];
    team: number[];
    jobFilters: number[];
  };
  teamLayout: { banner: LayoutObject; text: LayoutObject };
};

type JobSliderState = {
  slideIndex: number;
  dog: any;
};

export default class JobSlider extends Component<
  JobSliderProps,
  JobSliderState
> {
  constructor(props: JobSliderProps) {
    super(props);

    this.state = {
      slideIndex: 0,
      dog: undefined,
    };
  }

  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    if (nextState.slideIndex !== this.state.slideIndex) {
      if (nextState.slideIndex > this.state.slideIndex) {
        switch (nextState.slideIndex) {
          case 2:
            return this.props.setAnimationState('coffeeSlider', 4);
          case 3:
            return this.props.setAnimationState('coffeeSlider', 7);
          case 4:
            return this.props.setAnimationState('coffeeSlider', 10);
          default:
            return this.props.setAnimationState('coffeeSlider', 1);
        }
      } else {
        switch (nextState.slideIndex) {
          case 3:
            return this.props.setAnimationState('coffeeSlider', 11);
          case 2:
            return this.props.setAnimationState('coffeeSlider', 8);
          case 1:
            return this.props.setAnimationState('coffeeSlider', 5);
          default:
            return this.props.setAnimationState('coffeeSlider', 2);
        }
      }
    }
  }

  render() {
    return (
      <CarouselWrapper>
        <CarouselControls
          slides={this.props.teamSlides.length}
          prevDisabled={this.state.slideIndex === 0}
          nextDisabled={
            this.state.slideIndex === this.props.teamSlides.length - 1
          }
          onNextClick={() =>
            this.setState({
              slideIndex: this.state.slideIndex + 1,
            })
          }
          onPrevClick={() =>
            this.setState({
              slideIndex: this.state.slideIndex - 1,
            })
          }
          active={this.state.slideIndex}
          dark={!this.props.darkTheme}
        />
        <Carousel
          onChange={slideIndex => {
            this.setState({ slideIndex });
          }}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={this.state.slideIndex}
          swipeable
          emulateTouch
          showArrows={false}
          swipeScrollTolerance={3}
        >
          {this.props.teamSlides.map(team => {
            console.log(team.imageRoute);
            return (
              <>
                <GridSection gridRows={this.props.sectionHeight.banner}>
                  <BuddySliderBanner>
                    <Image
                      dark={this.props.darkTheme}
                      imageFolderUrl={team.imageRoute}
                    />
                  </BuddySliderBanner>

                  <GridBox
                    layout={this.props.teamLayout.banner}
                    style={{ alignContent: 'center' }}
                  >
                    {/* @ts-ignore */}
                    <Text.p darkTheme={this.props.darkTheme}>
                      {team.banner.label}
                    </Text.p>
                    {/* @ts-ignore */}
                    <Heading2 darkTheme={this.props.darkTheme}>
                      {team.banner.title}
                    </Heading2>
                  </GridBox>
                </GridSection>
                <GridSection
                  gridRows={this.props.sectionHeight.team}
                  dark={true}
                >
                  <GridBox layout={this.props.teamLayout.text}>
                    <Text.p>{team.text}</Text.p>
                  </GridBox>

                  <VisibilitySensor
                    onChange={isVisible => {
                      if (isVisible) {
                        !this.state.dog &&
                          this.props.setAnimationState('dog', 0);
                      }
                    }}
                  >
                    <Sprite
                      layout={dogLayout}
                      name={'dog'}
                      animationState={this.state.dog}
                      setAnimationState={this.props.setAnimationState}
                      isActive={true}
                      isSticky={false}
                      translateTop={-0.5}
                      animationsDefinitions={dogStates}
                    />
                  </VisibilitySensor>
                  <GridBox
                    layout={patternsLayout.p10}
                    className={'showAbove-mobile3'}
                  >
                    <SimpleImage src={'/assets/patterns/jobs/pattern-08.svg'} />
                  </GridBox>
                </GridSection>
              </>
            );
          })}
        </Carousel>
      </CarouselWrapper>
    );
  }
}

const StepImage = styled.div<{ urlL: string; stretched: boolean }>`
  background: url('${({ urlL }) => urlL}');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: ${({ stretched }) => (stretched ? '125%' : '100%')};
  transform: translateX(-160px);


  @media (max-width: 1279px) {
    transform: ${({ stretched }) => stretched && 'translateX(-120px)'};
  }

  @media (max-width: 1024px) {
    transform: ${({ stretched }) => stretched && 'translateX(-240px)'};
  }

  `;
