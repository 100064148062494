export const vinylStates = [
  {
    id: '01_start',
    duration: 1000,
    loop: false,
    amountOfFrames: 1,
    chainToState: 1,
  },
  {
    id: '02_loop',
    duration: 600,
    loop: true,
    amountOfFrames: 12,
  },
  {
    id: '03_scroll',
    duration: 800,
    loop: false,
    amountOfFrames: 13,
  },
  {
    id: '04_end',
    duration: 1700,
    loop: false,
    amountOfFrames: 37,
    freezeAfter: true,
  },
];

export const vinylStatesMobile = [
  {
    id: '01_start',
    duration: 1000,
    loop: false,
    amountOfFrames: 1,
    chainToState: 1,
  },
  {
    id: '02_loop',
    duration: 600,
    loop: false,
    amountOfFrames: 12,
    chainToState: 2,
  },
  {
    id: '03_scroll',
    duration: 800,
    loop: false,
    amountOfFrames: 13,
    chainToState: 3,
  },
  {
    id: '04_end',
    duration: 1700,
    loop: false,
    amountOfFrames: 37,
    freezeAfter: true,
  },
];

export const fishStates = [
  {
    id: '01_loop',
    duration: 4300,
    loop: true,
    amountOfFrames: 93,
  },
];

export const officesStates = [
  {
    id: '01_start',
    duration: 5000,
    loop: true,
    amountOfFrames: 1,
  },
  {
    id: '02_scroll',
    duration: 2000,
    loop: false,
    amountOfFrames: 13,
  },
  {
    id: '03_loop',
    duration: 2100,
    loop: true,
    amountOfFrames: 45,
    freezeAfter: true,
  },
];

export const officesStatesMobile = [
  {
    id: '01_start',
    duration: 500,
    loop: false,
    amountOfFrames: 1,
    chainToState: 1,
  },
  {
    id: '02_scroll',
    duration: 2000,
    loop: false,
    amountOfFrames: 13,
    chainToState: 2,
  },
  {
    id: '03_loop',
    duration: 2100,
    loop: true,
    amountOfFrames: 45,
    freezeAfter: true,
  },
];

export const dogStates = [
  {
    id: '01_loop',
    duration: 4500,
    loop: true,
    amountOfFrames: 100,
  },
];
