import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { graphql } from 'gatsby';
import {
  Header,
  RandomProfile,
  Image,
  Sprite,
  PageLayout,
} from '../../components/index';
import { GridSection, GridBox, FullWidthElement } from '../../components/Grid';
import {
  Heading1,
  Text,
  Heading2,
  TextSectionText,
} from '../../helper/Typography';
import { SimpleImage } from '../../helper/StyledHelper';
import {
  sectionHeight,
  intro,
  patternsLayout,
  textSection,
  fishLayout,
  imagesSection,
  textSection2,
  imagesSection2,
  jobOffersFrankfurt,
  jobOffersBerlin,
  team,
} from './layouts';
import { vinylStates, fishStates, officesStates } from './animations';
import { ContentfulJobofferConnection } from '../../generated/graphql-types';
import { LocationObject } from '../../components/component-types';
import PageEnhancer from '../../components/PageEnhancer';
import { SeoDataComponent } from '@lws/react-components';
import { mapMetaObject } from '../../helper/helperServices';
import JobSlider from '../../components/ImageCarousel/JobSlider';
import styled, { css } from 'styled-components';

interface EntryPageProps {
  location: LocationObject;
  data: {
    allContentfulJoboffer: ContentfulJobofferConnection;
  };
  pageContext: {
    generalTexts: any;
    routeTexts: any;
    locale: string;
    node_locale: string;
  };
}

interface JobOfferData {
  title: string;
  url: string;
  location: string;
  category: string;
}

class Jobs extends PageEnhancer {
  constructor(props: EntryPageProps) {
    super(props);
    this.state = {
      vinyl: undefined,
      fish: undefined,
      offices: undefined,
      dog: undefined,
      isScreenDesktop: true,
      darkTheme: false,
      category: this.props.location.state?.jobCat ?? '', //this.props.location.state.jobCat is created and passed down on click through the link component in teaser-component in jobOffer
      city: this.props.location.state?.city ?? '', //this.props.location.state.city is created and passed down on click through the link component in 'contact'. if there is no content, the auto-scroll isn´t triggered and the filters are set to default
      timerID: undefined,
    };
  }

  firstSetheightCalled = false;

  componentDidMount() {
    this.callSuperComponentDidMountMethods();
    this.setInitialAnimationsState([
      'vinyl',
      this.state.isScreenDesktop && 'offices',
    ]);
    window.addEventListener('message', this.iframeListener, false);
    if (this.props.location.state?.city) {
      this.setState({
        timerID: setTimeout(() => {
          const jobOffersSection = document.querySelector('#job-offers');
          const jobsTextSection = document.querySelector('#officesStart');
          const top =
            jobOffersSection &&
            jobsTextSection &&
            jobOffersSection.getBoundingClientRect().top +
              jobsTextSection.getBoundingClientRect().height;
          top &&
            window.scrollTo({
              behavior: 'smooth',
              top: top, // scroll so that the element is in the top of the view
            });
        }, 200),
      });
    }
  }

  UNSAFE_componentWillUpdate(
    nextProps: { [x: string]: any },
    nextState: { [x: string]: any }
  ) {
    if (
      nextState.isScreenDesktop &&
      typeof this.state.offices === 'undefined'
    ) {
      this.setAnimationState('offices', 0);
    }
  }

  iframeListener(e: any) {
    var iframe = document.querySelector(
      '#personio-iframe'
    )! as HTMLIFrameElement;
    var eventName = e.data[0];
    var data = e.data[1];
    switch (eventName) {
      case 'setHeight':
        iframe.style.height = data + 'px';
        if (this.firstSetheightCalled) {
          iframe?.scrollIntoView({ behavior: 'smooth' });
        }
        this.firstSetheightCalled = true;
        break;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.TimerID);
    window.removeEventListener('message', this.iframeListener);
  }

  setFilterOption(e) {
    this.setState(prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  }

  applyCategoryFilter(obj: JobOfferData, category: string) {
    let { routeTexts: jobs } = this.props.pageContext;

    if (
      category === 'All Jobs' ||
      category === '' ||
      category === `${jobs.categoryFilterLabel}`
    ) {
      return obj;
    }
    if (category === 'Operations') {
      return obj.category === 'Office management';
    } else {
      return obj.category === category;
    }
  }

  applyCityFilter(obj: JobOfferData, city: string) {
    let { routeTexts: jobs } = this.props.pageContext;
    if (
      city === '' ||
      city === 'All cities' ||
      city === `${jobs.cityFilterLabel}`
    ) {
      return obj;
    } else {
      return obj.location === city;
    }
  }

  render() {
    const { generalTexts, routeTexts: jobs, locale } = this.props.pageContext;
    const jobOffers = this.props.data.allContentfulJoboffer.nodes;
    const jobOffersByCity = {
      berlin: jobOffers.filter((offer: any) => offer.location === 'Berlin'),
      frankfurt: jobOffers.filter(
        (offer: any) => offer.location === 'Frankfurt am Main'
      ),
    };
    const availableCategories = [
      `${jobs.categoryFilterLabel}`,
      'Design',
      'Development',
      'Human Resources',
      'Project Management',
      'Operations',
    ]; //customize 'All categories' by lang
    const availableCities = [
      `${jobs.cityFilterLabel}`,
      'Berlin',
      'Frankfurt am Main',
    ]; //customize 'All cities' by lang
    const jobsFiltered: Array<JobOfferData> = jobOffers
      .filter((offer: JobOfferData) =>
        this.applyCategoryFilter(offer, this.state.category)
      )
      .filter((offer: any) => this.applyCityFilter(offer, this.state.city));
    const { isScreenDesktop } = this.state;

    return (
      <PageLayout
        userLanguage={locale}
        generalText={generalTexts}
        darkTheme={this.state.darkTheme}
      >
        <main className={this.state.darkTheme ? 'App Dark' : 'App Light'}>
          <Header
            darkTheme={this.state.darkTheme}
            lang={locale}
            pathname={this.props.location.pathname}
            generalTexts={generalTexts}
          />
          <SeoDataComponent data={mapMetaObject(jobs.meta).data} />
          <GridSection gridRows={sectionHeight.intro}>
            <GridBox layout={intro.title} style={{ alignContent: 'center' }}>
              <Text.pGrey style={{ marginTop: '40px' }}>
                {jobs.intro.label}
              </Text.pGrey>
              <Heading2>{jobs.intro.title}</Heading2>
            </GridBox>
            {typeof this.state.vinyl !== 'undefined' && (
              <Sprite
                id={'vinylStart'}
                layout={intro.vinyl}
                name={'vinyl'}
                startId={'vinylStart'}
                endId={'vinylEnd'}
                animationState={this.state.vinyl}
                setAnimationState={this.setAnimationState}
                isSticky={isScreenDesktop}
                isActive={true}
                originalAnimState={0}
                onRelease={() => {
                  this.state.vinyl !== 3 && this.setAnimationState('vinyl', 3);
                }}
                onScroll={() =>
                  this.state.vinyl !== 2 && this.setAnimationState('vinyl', 2)
                }
                animationsDefinitions={vinylStates}
              />
            )}
            <GridBox layout={patternsLayout.p1}>
              <SimpleImage
                src={'/assets/patterns/jobs/stage/pattern-v1-01.svg'}
              />
            </GridBox>
            <GridBox layout={patternsLayout.p2} style={{ width: '156%' }}>
              <SimpleImage
                src={'/assets/patterns/jobs/stage/pattern-v1-02.svg'}
              />
            </GridBox>
          </GridSection>

          <GridSection gridRows={sectionHeight.image}>
            <FullWidthElement>
              <Image imageFolderUrl={'/assets/pages/jobs/stage'} />
            </FullWidthElement>
            <GridBox
              id={'vinylEnd'}
              translateTop={0.5}
              layout={intro.vinylEnd}
            />
          </GridSection>

          <GridSection gridRows={sectionHeight.textSection}>
            <GridBox layout={textSection.title}>
              <Heading2 style={{ width: '75%' }}>
                {jobs.textSection.title}
              </Heading2>
            </GridBox>

            <GridBox layout={textSection.text}>
              {jobs.textSection.texts.map((text: string, idx: number) => (
                <TextSectionText key={`about-text-${idx}`}>
                  {text}
                </TextSectionText>
              ))}
            </GridBox>
            <VisibilitySensor
              onChange={isVisible => {
                if (isVisible) {
                  !this.state.fish && this.setAnimationState('fish', 0);
                }
              }}
            >
              <Sprite
                layout={fishLayout}
                name={'fish'}
                animationState={this.state.fish}
                setAnimationState={this.setAnimationState}
                isActive={true}
                isSticky={false}
                animationsDefinitions={fishStates}
                translateTop={-0.5}
              />
            </VisibilitySensor>
          </GridSection>

          <GridSection gridRows={sectionHeight.imagesSection}>
            <GridBox layout={imagesSection.imageLeft}>
              <Image imageFolderUrl={'/assets/pages/jobs/team1'} />
            </GridBox>
            <GridBox layout={imagesSection.imageRight}>
              <Image imageFolderUrl={'/assets/pages/jobs/team2'} />
            </GridBox>
            <GridBox layout={patternsLayout.p3}>
              <SimpleImage src={'/assets/patterns/jobs/pattern-01.svg'} />
            </GridBox>
            <GridBox layout={patternsLayout.p4} className={'showAbove-mobile3'}>
              <SimpleImage src={'/assets/patterns/jobs/pattern-02.svg'} />
            </GridBox>
          </GridSection>

          <GridSection gridRows={sectionHeight.textSection2}>
            <GridBox layout={textSection2.title}>
              <Heading1>{jobs.textSection2.title}</Heading1>
            </GridBox>
            <GridBox layout={patternsLayout.p5} className={'showAbove-tablet1'}>
              <SimpleImage src={'/assets/patterns/jobs/pattern-03.svg'} />
            </GridBox>
            <GridBox layout={patternsLayout.p6} className={'showAbove-mobile3'}>
              <SimpleImage src={'/assets/patterns/jobs/pattern-04.svg'} />
            </GridBox>
            <GridBox layout={patternsLayout.p7}>
              <SimpleImage src={'/assets/patterns/jobs/pattern-05.svg'} />
            </GridBox>
            <GridBox layout={patternsLayout.p8} className={'showAbove-tablet1'}>
              <SimpleImage src={'/assets/patterns/jobs/pattern-06.svg'} />
            </GridBox>
          </GridSection>

          <GridSection gridRows={sectionHeight.imagesSection2}>
            <GridBox layout={imagesSection2.imageLeft}>
              <Image imageFolderUrl={'/assets/pages/jobs/team3'} />
            </GridBox>
            <GridBox layout={imagesSection2.imageRight}>
              <Image imageFolderUrl={'/assets/pages/jobs/team4'} />
            </GridBox>
          </GridSection>

          <VisibilitySensor
            partialVisibility={true}
            minTopValue={700}
            onChange={isVisible => {
              this.setState({ darkTheme: isVisible });
            }}
          >
            <div id="job-offers">
              <GridSection gridRows={sectionHeight.jobOffersFrankfurt}>
                <GridBox
                  layout={jobOffersFrankfurt.description}
                  id={'officesStart'}
                  style={{ alignContent: 'end' }}
                >
                  <Text.pGrey darkTheme={this.state.darkTheme}>
                    {jobs.jobOffersFrankfurt.description.label}
                  </Text.pGrey>
                  <Heading1 darkTheme={this.state.darkTheme}>
                    {jobs.jobOffersFrankfurt.description.title}
                  </Heading1>
                  <Text.p
                    style={{ margin: 0 }}
                    darkTheme={this.state.darkTheme}
                  >
                    {jobs.jobOffersFrankfurt.description.text}
                  </Text.p>
                </GridBox>

                {isScreenDesktop && typeof this.state.offices !== 'undefined' && (
                  <Sprite
                    layout={jobOffersFrankfurt.officesStart}
                    name={'offices'}
                    startId={'officesStart'}
                    endId={'officesEnd'}
                    animationState={this.state.offices}
                    setAnimationState={this.setAnimationState}
                    isSticky={isScreenDesktop}
                    isActive={true}
                    originalAnimState={0}
                    onRelease={() => {
                      this.state.offices !== 2 &&
                        this.setAnimationState('offices', 2);
                    }}
                    onScroll={() =>
                      this.state.offices !== 1 &&
                      this.setAnimationState('offices', 1)
                    }
                    animationsDefinitions={officesStates}
                  />
                )}
              </GridSection>

              <JobSection>
                <iframe
                  id="personio-iframe"
                  src={`https://neugelb-studios-gmbh.jobs.personio.de/?language=${locale}`}
                  width="100%"
                  height="100%"
                  style={{ border: 'none' }}
                ></iframe>
                <GridBox
                  id={'officesEnd'}
                  layout={jobOffersBerlin.officesEnd}
                />
              </JobSection>

              <JobSlider
                teamLayout={team}
                teamSlides={jobs.teamSlides}
                setAnimationState={this.setAnimationState}
                darkTheme={this.state.darkTheme}
                sectionHeight={sectionHeight}
              />

              <RandomProfile
                userLanguage={locale}
                darkTheme={this.state.darkTheme}
              />
            </div>
          </VisibilitySensor>
        </main>
      </PageLayout>
    );
  }
}

export default Jobs;

export const query = graphql`
  query($node_locale: String) {
    allContentfulJoboffer(filter: { node_locale: { eq: $node_locale } }) {
      nodes {
        node_locale
        title
        url
        location
        category
      }
    }
  }
`;

export const JobSection = styled.div`
  max-width: 800px;
  margin: 100px auto;
`;
