// BPS:
// 2 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x

export const sectionHeight = {
  intro: [5, 5, 5, 5, 5, 4, 4, 4, 6],
  image: [6, 6, 6, 4, 4, 4, 4, 4, 4],
  textSection: [5, 5, 5, 5, 5, 4, 4, 4, 4],
  //todo: missing definitions for this section
  imagesSection: [6, 6, 6, 6, 5, 5, 5, 5, 5],
  textSection2: [5, 5, 5, 6, 7, 6, 6, 6, 6],
  imagesSection2: [6, 6, 5, 5, 5, 5, 5, 5, 5],
  jobOffersFrankfurt: [4, 4, 4, 5, 5, 3, 3, 3, 3],
  jobOffersBerlin: [3, 3, 3, 3, 2, 2, 2, 2, 2],
  banner: [6, 6, 6, 6, 4, 4, 4, 4, 4],
  team: [5, 4, 3, 2, 2, 1, 1, 1, 1],
  jobFilters: [1, 1, 1, 1, 1, 1, 1, 1, 1]
};

export const intro = {
  title: {
    top: [3, 4, 4, 4, 4, 3, 3, 3, 5],
    left: [1, 1, 1, 1, 1, 2, 2, 2, 4],
    boxWidth: [2, 3, 4, 4, 4, 3, 3, 3, 3],
    boxHeight: [2, 2, 2, 1, 1, 1, 1, 1, 1],
  },
  vinyl: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [2, 2, 2, 2, 2, 2, 2, 2, 3],
    left: [2, 2, 3, 5, 6, 6, 7, 8, 8],
  },
  vinylEnd: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    left: [2, 2, 3, 5, 6, 6, 7, 8, 8],
  },
};

export const textSection = {
  title: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 2, 2, 3, 3, 4],
    boxWidth: [3, 4, 3, 3, 2, 2, 2, 2, 2],
    boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  },
  text: {
    top: [3, 3, 3, 3, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 5, 5, 6, 7, 8],
    boxWidth: [3, 3, 4, 4, 4, 3, 3, 3, 3],
    boxHeight: [5, 4, 3, 3, 3, 2, 2, 2, 2],
  },
};

export const textSection2 = {
  title: {
    top: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left: [1, 1, 1, 1, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 5, 5, 4, 4, 4, 4],
    boxHeight: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
};

export const imagesSection = {
  imageLeft: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
    boxWidth: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    boxHeight: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  imageRight: {
    top: [4, 4, 4, 4, 3, 3, 3, 3, 3],
    left: [2, 2, 2, 3, 5, 5, 6, 7, 8],
    boxWidth: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    boxHeight: [3, 3, 3, 3, 3, 3, 3, 3, 3],
  },
};

export const imagesSection2 = {
  imageLeft: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 1, 2, 2, 3, 4, 5],
    boxWidth: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    boxHeight: [3, 3, 3, 3, 3, 3, 3, 3, 3],
  },
  imageRight: {
    top: [4, 4, 4, 3, 3, 3, 3, 3, 3],
    left: [2, 2, 2, 4, 5, 5, 6, 7, 8],
    boxWidth: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    boxHeight: [2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
};

export const jobOffersFrankfurt = {
  description: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [4, 4, 4, 5, 5, 3, 3, 3, 3],
  },
  links: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    // boxHeight: [4, 4, 4, 4, 4, 4, 4, 4, 4]
  },
  officesStart: {
    top: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left: [3, 3, 4, 6, 8, 8, 10, 10, 12],
  },
};

export const jobOffersBerlin = {
  description: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [3, 3, 3, 3, 2, 2, 2, 2, 2],
  },
  links: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [3, 3, 4, 4, 4, 4, 4, 4, 4],
    // boxHeight: [4, 4, 4, 4, 4, 4, 4, 4, 4]
  },
  officesEnd: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [3, 3, 4, 6, 8, 8, 10, 10, 12],
  },
};

export const team = {
  banner: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 2, 2, 2, 2, 3, 4],
    boxWidth: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    boxHeight: [6, 6, 6, 6, 4, 4, 4, 4, 4],
  },
  text: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
    boxWidth: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  },
};

export const fishLayout = {
  top: [1, 1, 1, 1, 4, 4, 4, 4, 4],
  left: [1, 1, 1, 2, 1, 2, 2, 3, 4],
};

export const dogLayout = {
  top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
  left: [1, 1, 1, 2, 3, 3, 4, 5, 6],
};

export const patternsLayout = {
  p1: {
    top: [2, 2, 2, 2, 2, 2, 2, 3, 3],
    left: [3, 3, 4, 6, 7, 7, 8, 9, 9],
  },
  p2: {
    top: [3, 3, 3, 3, 3, 3, 3, 3, 4],
    left: [3, 3, 4, 6, 7, 7, 8, 9, 9],
    boxWidth: [2, 2, 2, 1, 2, 2, 2, 2, 2],
  },
  p3: {
    top: [4, 4, 4, 4, 3, 3, 3, 3, 3],
    left: [1, 1, 1, 1, 1, 1, 2, 3, 4],
  },
  p4: {
    top: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left: [3, 3, 4, 5, 8, 8, 9, 10, 11],
  },
  p5: {
    top: [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left: [1, 1, 1, 1, 1, 1, 2, 3, 4],
  },
  p6: {
    top: [5, 5, 5, 3, 3, 3, 3, 3, 3],
    left: [3, 3, 4, 6, 8, 8, 9, 10, 11],
  },
  p7: {
    top: [5, 5, 5, 4, 4, 4, 4, 4, 4],
    left: [3, 3, 4, 6, 8, 8, 9, 10, 11],
  },
  p8: {
    top: [6, 6, 6, 7, 7, 6, 6, 6, 6],
    left: [1, 1, 1, 1, 1, 1, 2, 3, 4],
  },
  p9: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 1, 1, 1, 1, 2, 3],
  },
  p10: {
    top: [4, 4, 4, 4, 4, 3, 3, 3, 3],
    left: [1, 1, 1, 1, 1, 1, 1, 2, 3],
  },
  p11: {
    top: [4, 4, 4, 4, 3, 3, 3, 3, 3],
    left: [1, 1, 1, 1, 1, 1, 1, 2, 3],
  },
};
